import WPhoto_LiftedShadowPhotoComponent from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/skinComps/BasicWPhoto/LiftedShadowPhoto.skin';
import WPhoto_LiftedShadowPhotoController from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/WPhoto.controller';


const WPhoto_LiftedShadowPhoto = {
  component: WPhoto_LiftedShadowPhotoComponent,
  controller: WPhoto_LiftedShadowPhotoController
};


export const components = {
  ['WPhoto_LiftedShadowPhoto']: WPhoto_LiftedShadowPhoto
};

